import React from "react";
import Main from "../components/Main";
import styled from "styled-components";

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import shortId from "shortid";
import "react-datepicker/dist/react-datepicker.css";

const Button = styled.button`
  display: block;
  margin-top: 16px;
  border: 0;
  outline: 0;
  padding: 12px 24px;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    margin-left: 8px;
    fill: white;
  }

  &:hover {
    background-color: #3c987b;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const Input = styled.input`
  height: 42px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 6px;

  & > * {
    flex: 1 0 0;
    margin-right: 16px;
  }

  & input {
    width: 100%;
    height: 42px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    box-sizing: border-box;
  }
`;

export default () => {
  const [state, setState] = React.useState({
    loading: true,
    items: [],
    loggedIn: false,
    lastSaved: null,
  });

  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (state.loggedIn) {
      fetch("/api/agenda")
        .then((res) => res.json())
        .then((items) => setState((s) => ({ ...s, items })));
    }
  }, [state.loggedIn]);

  function handleChange(id, key) {
    return function(evt) {
      const items = state.items;
      const index = items.findIndex((item) => item.id === id);
      const newItems = items.slice(0);
      const newItem = {
        ...items[index],
        [key]: evt.target.value,
      };
      newItems.splice(index, 1, newItem);
      setState({ ...state, items: newItems });
    };
  }

  return (
    <Main frontpage title="Home" path="/admin">
      <div style={{ maxWidth: 900, margin: "auto" }}>
        {state.loggedIn ? (
          <div>
            <h1>Agenda</h1>
            <Row style={{ fontWeight: "bold" }}>
              <div>Locatie</div>
              <div>Plaats</div>
              <div>Datum/Tijd</div>
              <div>Link</div>
              <div style={{ flex: 0, width: 24 }}></div>
            </Row>
            {state.items.map((item) => {
              return (
                <Row key={item.id}>
                  <div>
                    <Input
                      onChange={handleChange(item.id, "location")}
                      value={item.location}
                    />
                  </div>
                  <div>
                    <Input
                      onChange={handleChange(item.id, "city")}
                      value={item.city}
                    />
                  </div>
                  <div>
                    <DatePicker
                      selected={new Date(item.date)}
                      onChange={(date) => {
                        const items = state.items;
                        const index = items.findIndex((x) => x.id === item.id);
                        const newItems = items.slice(0);
                        const newItem = {
                          ...items[index],
                          date,
                        };
                        newItems.splice(index, 1, newItem);
                        setState({ ...state, items: newItems });
                      }}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="MM-dd-yyyy HH:mm"
                    />
                  </div>
                  <div>
                    <Input
                      onChange={handleChange(item.id, "link")}
                      value={item.link}
                    />
                  </div>
                  <div style={{ flex: 0, width: 24 }}>
                    <button
                      onClick={() => {
                        setState((s) => ({
                          ...s,
                          items: s.items.filter((x) => x.id !== item.id),
                        }));
                      }}
                    >
                      x
                    </button>
                  </div>
                </Row>
              );
            })}

            <div style={{ marginTop: 48, display: "flex" }}>
              <Button
                style={{
                  margin: 0,
                  backgroundColor: "#f3f3f3",
                  color: "#333",
                  marginRight: 12,
                }}
                onClick={() => {
                  setState((s) => ({
                    ...s,
                    items: s.items.concat({
                      id: shortId(),
                      date: new Date().toString(),
                      location: "",
                      city: "",
                    }),
                  }));
                }}
              >
                Evenement toevoegen
              </Button>
              <Button
                style={{ margin: 0 }}
                onClick={() => {
                  fetch("/api/agenda", {
                    method: "POST",
                    body: JSON.stringify(state.items),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }).then(() => {
                    setState((x) => ({ ...x, lastSaved: new Date() }));
                  });
                }}
              >
                Opslaan
              </Button>
            </div>

            <div style={{ marginTop: 32, color: "grey" }}>
              Laatst opgeslagen op:{" "}
              {state.lastSaved ? format(state.lastSaved, "hh:mm:ss") : "-"}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1>Login</h1>
            <Input
              type="password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
            <Button
              disabled={password === ""}
              onClick={() => {
                if (password === "Hatseflats") {
                  setState((s) => ({ ...s, loggedIn: true }));
                } else {
                  setPassword("");
                  alert("Ongeldig wachtwoord");
                }
              }}
            >
              Login
            </Button>
          </div>
        )}
      </div>
    </Main>
  );
};
